"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KanKan = void 0;
require("../scss/styles.scss");
require("./common");
const Mustache = require('mustache');
Mustache.tags = ['<%', '%>'];
var KanKan;
(function (KanKan) {
    const worker = new Worker('static/js/kankan-worker.js');
    let radcompat = {};
    let last_pattern = null;
    let pending_pattern = null;
    let worker_ready = false;
    let radical_set = new Set();
    let verbose = getURLParameter('v') === '1';
    function getURLParameter(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }
    let last_history_update = new Date();
    function setURLParameter(name, value) {
        if ((getURLParameter(name) || '') === value) {
            return;
        }
        const url = new URL(window.location.href);
        if (value === '') {
            url.searchParams.delete(name);
        }
        else {
            url.searchParams.set(name, value);
        }
        if (new Date().getTime() - last_history_update.getTime() < 2000) {
            window.history.replaceState({}, '', url);
            last_history_update = new Date();
        }
        else {
            window.history.pushState({}, '', url);
            last_history_update = new Date();
        }
    }
    function reformat_search_string(search_input) {
        let open_brackets = 0;
        let s = search_input.value;
        let ns = '';
        for (let i = 0; i < s.length; i++) {
            let c = s[i];
            if ('<〈＜「'.indexOf(c) >= 0) {
                open_brackets++;
                c = '「';
            }
            else if ('>〉＞」'.indexOf(c) >= 0) {
                open_brackets--;
                c = '」';
            }
            else if (c === '?') {
                c = '？';
            }
            else if (c === '*') {
                c = '＊';
            }
            else if (',、､'.indexOf(c) >= 0) {
                if (open_brackets > 0) {
                    c = '」';
                    open_brackets--;
                }
                else {
                    c = '「';
                    open_brackets++;
                }
            }
            ns += c;
        }
        if (ns != search_input.value && open_brackets == 0) {
            let start = search_input.selectionStart;
            let end = search_input.selectionEnd;
            search_input.value = ns;
            search_input.setSelectionRange(start, end);
        }
    }
    function update_radical_picker() {
        let search_input = requestElementById('ksbar', HTMLInputElement);
        let start = search_input.selectionStart || 0;
        let end = search_input.selectionEnd || 0;
        if (start != end) {
            // We have a selection active
            update_radical_selection('');
            return;
        }
        let s = search_input.value;
        // Find the brackets
        let rad_start = undefined;
        for (let i = start - 1; i >= 0; i--) {
            if (s[i] == '」') {
                break;
            }
            else if (s[i] == '「') {
                rad_start = i + 1;
                break;
            }
        }
        let rad_end = undefined;
        for (let i = start; i < s.length; i++) {
            if (s[i] == '「') {
                break;
            }
            else if (s[i] == '」') {
                rad_end = i;
                break;
            }
        }
        if (rad_start === undefined || rad_end === undefined) {
            update_radical_selection('');
            return;
        }
        let radicals = s.slice(rad_start, rad_end);
        update_radical_selection(radicals);
    }
    let search_cache = [];
    function get_cached_search(pattern) {
        return search_cache.find((data) => data.pattern === pattern);
    }
    function add_cached_search(data) {
        if (search_cache.length > 10) {
            search_cache.shift();
        }
        search_cache.push(data);
    }
    function maybe_start_search() {
        if (!worker_ready || pending_pattern === null) {
            return;
        }
        const d = get_cached_search(pending_pattern);
        if (d) {
            display_search_results(d);
            return;
        }
        worker.postMessage({
            action: 'search',
            pattern: pending_pattern,
            token: random_token(),
        });
        pending_pattern = null;
    }
    function fuzzy_search(search_input) {
        reformat_search_string(search_input);
        update_radical_picker();
        let pattern = search_input.value;
        if (pattern === last_pattern) {
            return;
        }
        setURLParameter('s', pattern);
        if (!pattern) {
            let results = requestElementById('ksresults');
            results.innerHTML = '';
            return;
        }
        pending_pattern = pattern;
        maybe_start_search();
    }
    function init_radical_input(radicals, search_input) {
        let rlist = requestElementById('radical-list');
        let cur_strokes = -1;
        function input_radical() {
            let elem = this;
            let radical = elem.textContent;
            let selmode = elem.getAttribute('selmode');
            let s = search_input.value;
            let selstart = search_input.selectionStart || 0;
            let selend = search_input.selectionEnd || 0;
            let open_brackets = 0;
            if (selmode == 'selected') {
                // Deselect the radical
                let rad_start = undefined;
                for (let i = selstart - 1; i >= 0; i--) {
                    if (s[i] == '」') {
                        break;
                    }
                    else if (s[i] == '「') {
                        rad_start = i + 1;
                        break;
                    }
                }
                let rad_end = undefined;
                for (let i = selstart; i < s.length; i++) {
                    if (s[i] == '「') {
                        break;
                    }
                    else if (s[i] == '」') {
                        rad_end = i;
                        break;
                    }
                }
                if (rad_start == undefined || rad_end == undefined) {
                    console.error('selected radical without block?', s, selstart);
                    return;
                }
                let selrads = new Set();
                for (let r of document.querySelectorAll('.radical-selector[selmode="selected"]')) {
                    if (r !== this) {
                        selrads.add(r.textContent);
                    }
                }
                let rads = Array.from(selrads).sort().join('');
                search_input.value = s.slice(0, rad_start) + rads + s.slice(rad_end);
                rad_end = rad_start + rads.length;
                search_input.setSelectionRange(rad_end, rad_end);
            }
            else {
                for (let i = 0; i < selstart; i++) {
                    if (s[i] == '「') {
                        open_brackets++;
                    }
                    else if (s[i] == '」') {
                        open_brackets--;
                    }
                }
                let start = s.slice(0, selstart);
                let end = s.slice(selend);
                if (open_brackets <= 0) {
                    search_input.value = start + '「' + radical + '」' + end;
                    if (selend == selstart) {
                        selend += 2;
                    }
                    else {
                        selend += 3;
                    }
                    selstart += 2;
                }
                else {
                    search_input.value = start + radical + end;
                    selstart += 1;
                    selend += 1;
                }
                search_input.setSelectionRange(selstart, selend);
            }
            setTimeout(function () {
                fuzzy_search(search_input);
            }, 0);
        }
        // Move ⻖ next to ⻏ for clarity
        {
            let from = radicals.findIndex(function (r) {
                return r.radical == '⻖';
            });
            let r = radicals.splice(from, 1)[0];
            let to = radicals.findIndex(function (r) {
                return r.radical == '⻏';
            });
            radicals.splice(to, 0, r);
        }
        radicals.forEach(function (r) {
            radical_set.add(r.radical);
            if (r.strokes !== cur_strokes) {
                let s = document.createElement('span');
                s.classList.add('radical-strokes');
                s.textContent = r.strokes.toString();
                rlist.appendChild(s);
                cur_strokes = r.strokes;
            }
            let s = document.createElement('span');
            s.classList.add('radical-selector');
            s.id = radical_id(r.radical);
            if ('⻏⻖'.indexOf(r.radical) >= 0) {
                // These radicals look identical in the Japanese locale at least
                // on some devices (Firefox Windows for instance), switching to
                // chinese seems to fix it...
                s.setAttribute('lang', 'zh');
            }
            s.textContent = r.radical;
            rlist.appendChild(s);
            s.onclick = input_radical;
        });
    }
    function update_radical_selection(radicals) {
        let all_radicals = document.querySelectorAll('.radical-selector');
        if (!radicals) {
            for (let rp of all_radicals) {
                rp.setAttribute('selmode', 'available');
            }
            return;
        }
        for (let rp of all_radicals) {
            rp.setAttribute('selmode', 'unavailable');
        }
        let used_rads = new Set();
        for (let r of radicals) {
            if (radical_set.has(r)) {
                used_rads.add(r);
                let rp = document.getElementById(radical_id(r));
                if (rp) {
                    rp.setAttribute('selmode', 'selected');
                }
            }
            else {
                // This is probably a non-radical kanji, consider its sub-parts
                // as selected
                for (let sr of radcompat[r] || []) {
                    used_rads.add(sr);
                    let rp = document.getElementById(radical_id(sr));
                    if (rp) {
                        rp.setAttribute('selmode', 'selected');
                    }
                }
            }
        }
        let compat_rads = undefined;
        for (let r of used_rads.values()) {
            let rads = radcompat[r];
            if (rads === undefined) {
                continue;
            }
            let s = new Set(rads);
            if (compat_rads === undefined) {
                compat_rads = s;
            }
            else {
                compat_rads = compat_rads.intersection(s);
            }
        }
        for (let r of compat_rads || []) {
            if (used_rads.has(r)) {
                continue;
            }
            let rp = document.getElementById(radical_id(r));
            if (rp) {
                rp.setAttribute('selmode', 'available');
            }
        }
    }
    const classification_desc = {
        char: 'character',
        co: 'company name',
        creat: 'creature',
        god: 'deity',
        doc: 'document',
        ev: 'event',
        fem: 'female given name or forename',
        fict: 'fiction',
        given: 'given name or forename, gender not specified',
        grp: 'group',
        leg: 'legend',
        masc: 'male given name or forename',
        myth: 'mythology',
        obj: 'object',
        org: 'organization name',
        oth: 'other',
        pers: 'full name of a particular person',
        place: 'place name',
        product: 'product name',
        relig: 'religion',
        serv: 'service',
        ship: 'ship name',
        eki: 'railway station',
        fam: 'family or surname',
        unclass: 'unclassified name',
        work: 'work of art, literature, music, etc. name',
    };
    function display_search_results(data) {
        let results = requestElementById('ksresults');
        results.innerHTML = '';
        let matches = data.matches || [];
        const count_span = document.createElement('div');
        const result_count = document.createElement('div');
        result_count.classList.add('result-count');
        result_count.textContent = data.nmatches + ' result(s) found';
        results.appendChild(result_count);
        let template_data = {
            nmatches: data.nmatches || 0,
            matches: [],
        };
        template_data.matches = matches.map((match) => {
            let w = match.word;
            let d = match.dict;
            let o = match.match_offset || 0;
            let l = match.match_len || w.length;
            let pre = w.slice(0, o);
            let m = w.slice(o, o + l);
            let post = w.slice(o + l);
            let data = {
                word: w,
                table: d.t,
                index: d.i,
                readings: (d.R || []).join('、'),
                meanings: (d.M || []).join('; '),
                is_kanji: d.t == 'kanji',
                is_name: d.t == 'nanori',
                freq: d.f < 100000 ? Math.ceil(d.f / 1000) : null,
                match: m,
            };
            if (pre) {
                data.pre_match = pre;
            }
            if (post) {
                data.post_match = post;
            }
            data.alt_forms = d.F.filter((f) => f !== w).join('・');
            return data;
        });
        const rendered = renderTemplate('tmpl-match-results', template_data);
        results.innerHTML = rendered;
        let li = document.querySelectorAll('li.match');
        const click_handler = function (ev) {
            return __awaiter(this, void 0, void 0, function* () {
                let l = this;
                let url = l.getAttribute('data-url');
                if (!url) {
                    return;
                }
                if (l.classList.contains('has_def')) {
                    return;
                }
                l.classList.add('loading');
                try {
                    const response = yield fetch(url);
                    if (!response.ok) {
                        throw new Error(`Fetch failed: ${response.status}`);
                    }
                    const entry = yield response.json();
                    let rendered = null;
                    if (entry.table == 'goi' || entry.table == 'nanori') {
                        let entry_data = {
                            kanji_forms: entry.F.join('・'),
                            readings: entry.R.join('、'),
                            meanings: entry.M,
                            meanings_concat: entry.M.join('; '),
                            kanji_details: entry.kanji,
                            classification: classification_desc[entry.c] || '',
                            jisho_url: entry.jisho_url,
                            tags: entry.m,
                            pos: entry.p,
                            examples: entry.e,
                        };
                        if (entry.f && entry.f < 400000) {
                            entry_data.freq = Math.ceil(entry.f / 1000);
                        }
                        if (entry_data.kanji_details) {
                            for (let k of entry_data.kanji_details) {
                                k.M = k.M.join(', ');
                            }
                        }
                        if (entry_data.pos) {
                            entry_data.pos = entry_data.pos.join(', ');
                        }
                        rendered = renderTemplate('tmpl-goi-entry', entry_data);
                    }
                    else if (entry.table == 'kanji') {
                        let entry_data = {
                            kanji: entry.K,
                            on: entry.R.join('、'),
                            kun: entry.kun.join('、'),
                            meanings_concat: entry.M.join('; '),
                            meanings: entry.M,
                            jisho_url: entry.jisho_url,
                            svg: entry.svg,
                        };
                        rendered = renderTemplate('tmpl-kanji-entry', entry_data);
                    }
                    if (rendered) {
                        l.querySelectorAll('.temp-def')[0].remove();
                        l.classList.remove('match-stub');
                        let full_def = l.querySelectorAll('.full-def')[0];
                        full_def.innerHTML = rendered;
                        full_def.classList.add('rendered');
                        l.removeEventListener('click', click_handler);
                    }
                    l.classList.add('has_def');
                    let cb_elements = l.querySelectorAll('[data-clipboard]');
                    for (let e of cb_elements) {
                        let s = e.getAttribute('data-clipboard') || '';
                        if (!s) {
                            return;
                        }
                        e.addEventListener('click', (ev) => {
                            navigator.clipboard.writeText(s).then(function () {
                                console.log(s);
                                if (s.length > 16) {
                                    s = s.slice(0, 16) + '...';
                                }
                                console.log(s);
                                toast(`【${s}】 copied to the clipboard`);
                            }, function (err) {
                                console.error(`Could not copy ${s}: `, err);
                            });
                        });
                        e.classList.add('copyable');
                    }
                }
                catch (error) {
                    console.error('Error fetching JSON data:', error);
                }
                finally {
                    l.classList.remove('loading');
                }
            });
        };
        for (const l of li) {
            l.addEventListener('click', click_handler.bind(l));
        }
    }
    function init() {
        return __awaiter(this, void 0, void 0, function* () {
            let search_input = requestElementById('ksbar', HTMLInputElement);
            search_input.value = getURLParameter('s') || '';
            let response = yield fetch('static/data/radicals.json');
            if (!response.ok) {
                throw new Error("Can't load radicals.json: " + response.statusText);
            }
            let radicals = yield response.json();
            response = yield fetch('static/data/radicalcompat.json');
            if (!response.ok) {
                throw new Error("Can't load radicalcompat.json: " + response.statusText);
            }
            radcompat = yield response.json();
            init_radical_input(radicals, search_input);
            search_input.addEventListener('input', function () {
                reformat_search_string(search_input);
                fuzzy_search(search_input);
            });
            search_input.addEventListener('change', function () {
                fuzzy_search(search_input);
            });
            window.addEventListener('popstate', function () {
                search_input.value = getURLParameter('s') || '';
                fuzzy_search(search_input);
            });
            search_input.addEventListener('keyup', update_radical_picker);
            search_input.addEventListener('click', update_radical_picker);
            let clear = requestElementById('clear-button', HTMLButtonElement);
            clear.addEventListener('click', function () {
                search_input.value = '';
                search_input.focus();
                setTimeout(function () {
                    fuzzy_search(search_input);
                }, 0);
            });
            setTimeout(function () {
                fuzzy_search(search_input);
            }, 0);
        });
    }
    document.addEventListener('DOMContentLoaded', init);
    function random_token() {
        return 'T#' + Math.random().toString(36).substr(2);
    }
    function radical_id(r) {
        return 'rad-' + r.charCodeAt(0).toString(16);
    }
    worker.onmessage = function (event) {
        let data = event.data;
        console.log('main rx', data);
        switch (data.action) {
            case 'worker_ready':
                worker_ready = true;
                let dict_entries = requestElementById('dict-entry-count');
                dict_entries.textContent = (data.dict_entries || 0).toLocaleString();
                maybe_start_search();
                break;
            case 'search_results':
                if (data.progress === 100) {
                    add_cached_search(data);
                }
                display_search_results(data);
                break;
            case 'search_done':
                maybe_start_search();
                break;
            default:
                console.error('Unknown action', data);
        }
    };
    /* Dark/light mode handling */
    document.addEventListener('DOMContentLoaded', () => {
        const toggleCheckbox = requestElementById('theme-toggle', HTMLInputElement);
        const currentTheme = localStorage.getItem('theme');
        const applyTheme = (theme) => {
            document.documentElement.setAttribute('data-theme', theme);
            localStorage.setItem('theme', theme);
            toggleCheckbox.checked = theme === 'light';
        };
        if (currentTheme) {
            applyTheme(currentTheme);
        }
        else {
            const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
            applyTheme(prefersDarkScheme ? 'dark' : 'light');
        }
        toggleCheckbox.addEventListener('change', () => {
            const newTheme = toggleCheckbox.checked ? 'light' : 'dark';
            applyTheme(newTheme);
        });
    });
    /* Extended dictionary handling */
    document.addEventListener('DOMContentLoaded', () => {
        const toggleCheckbox = requestElementById('extended-dict-toggle', HTMLInputElement);
        const nanoriToggleCheckbox = requestElementById('nanori-dict-toggle', HTMLInputElement);
        let cur_dict = localStorage.getItem('dict');
        let nanori = localStorage.getItem('nanori') == '1';
        if (!cur_dict) {
            cur_dict = 'hf';
        }
        toggleCheckbox.checked = cur_dict === 'full';
        nanoriToggleCheckbox.checked = nanori;
        worker.postMessage({
            action: 'init',
            dict: cur_dict,
            nanori: nanori,
        });
        toggleCheckbox.addEventListener('change', () => {
            const new_dict = toggleCheckbox.checked ? 'full' : 'hf';
            localStorage.setItem('dict', new_dict);
            // Refresh page
            window.location.reload();
        });
        nanoriToggleCheckbox.addEventListener('change', () => {
            const nanori = nanoriToggleCheckbox.checked ? '1' : '0';
            localStorage.setItem('nanori', nanori);
            // Refresh page
            window.location.reload();
        });
    });
    function requestElementById(id, type = HTMLElement) {
        const elem = document.getElementById(id);
        if (!elem) {
            throw new Error(`No element with ID ${id}`);
        }
        if (!(elem instanceof type)) {
            throw new Error(`Element with ID ${id} is not of type ${type.name}`);
        }
        return elem;
    }
    function renderTemplate(name, data) {
        const tmpl = requestElementById(name, HTMLScriptElement).innerHTML;
        return Mustache.render(tmpl, data);
    }
    let toast_tout = null;
    function toast(msg) {
        let e = requestElementById('toast');
        e.textContent = msg;
        e.classList.add('show');
        if (toast_tout) {
            clearTimeout(toast_tout);
        }
        toast_tout = setTimeout(() => {
            e.classList.remove('show');
        }, 3000);
    }
})(KanKan || (exports.KanKan = KanKan = {}));
